<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>库存盘点</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-form class="search-form" @submit.prevent>
      <el-row :gutter="10">
        <!-- <el-form-item label="" label-width="0">
          <el-col>
            <el-button type="info" class="el-icon-back" @click="back()"> 返回 </el-button>
          </el-col>
        </el-form-item> -->

        <el-col :span="7">
          <el-form-item label-width="120px" label="所属公司：" prop="companyId">
            <!-- <el-cascader v-model="companyId" :options="companys" @change="companyChange" style="max-width: 295px;"
              :disabled="finishStatus || billCode" /> -->
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="companyChange();"
              :disabled="finishStatus || billCode" check-strictly :render-after-expand="false"
              style="min-width: 220px;max-width: 295px;" />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="盘点类型：" label-width="100px">
            <el-select v-model="master.billType" placeholder="请选择" :disabled="(master.billCode || '') != ''"
              style="max-width: 120px;">
              <el-option v-for="item in billTypes" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据编号：" label-width="100px">
            <el-input v-model="master.billCode" style="max-width: 200px" @change="fetchs" :disabled="true">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="90px" label="">
            <el-col>
              <span v-if="status == -1" style="color: #ccc;font-size: large;">盘点已取消</span>
              <span v-else-if="status == 0" style="color:#67d400;font-size: large;">盘点进行中</span>
              <span v-else-if="status == 1" style="color:#f56c6c;font-size: large;">盘点已暂停</span>
              <span v-else-if="status == 10" style="color:#1e00ff;font-size: large;">盘点已完成</span>
              <span v-else>未知状态</span>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="!finishStatus">
        <el-col :span="7">
          <el-form-item label-width="120px" label="货号/品牌货号：" prop="keyword">
            <el-input v-model="keyword" placeholder="回车Enter进行搜索" @keyup.enter="searchEvent" :disabled="finishStatus"
              style="max-width: 200px;">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="100px" label="盘点数：" prop="total">
            <el-input-number v-model="total" @keyup.enter="searchEvent" :precision="2" :step="1" :min="0" :max="10000"
              style="max-width: 120px" :disabled="finishStatus" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label-width="100px" label="备注描述：">
            <el-input v-model="remark" placeholder="盘点异常时填写" @keyup.enter="searchEvent" :disabled="finishStatus"
              style="max-width: 200px">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item label-width="90px" label="">
            <el-button plain type="success" icon="Iphone" @click="scanEvent()" v-if="!finishStatus">微信扫码盘点</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="3">
          <el-form-item label-width="120px" label="总数量：" v-if="master.originNumber != null && master.originNumber >= 0">
            {{ master.originNumber }}
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label-width="120px" label="盘点数：" v-if="master.totalNumber != null && master.totalNumber >= 0">
            {{ master.totalNumber }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label-width="120px" label="差异数：" v-if="master.diffNumber != null && master.diffNumber >= 0">
            {{ master.diffNumber }}
            <span style="margin-left:30px">差异金额：{{ master.diffMoney }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="4">
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="90px" label="">
            <el-col>
              <el-button plain type="primary" icon="Discount" @click="openEndEvent()">
                <span v-if="finishStatus">查看盘点结果</span>
                <span v-else>保存盘点结果</span>
              </el-button>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>

    <el-tabs type="border-card" v-model="tabName" @tab-change="tabChange">
      <el-tab-pane name="current">
        <template #label>
          <span>
            <i class="el-icon-circle-check" style="color: #67c23a"></i>
            已盘点 <span v-if="hasCount > 0">{{ hasCount || 0 }}</span>
          </span>
        </template>

        <el-form :inline="true" @submit.prevent>
          <el-form-item label="关键词">
            <el-input v-model="querys.keyword" placeholder="搜索已盘点的货品" @keyup.enter="fetchs"> </el-input>
          </el-form-item>
        </el-form>

        <static-table class="table" :pager="querys" :columns="querys.columns" :currHandler="currHandler"
          :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
          <template v-slot:detailImg="scope">
            <el-image style="height: 32px; width: 32px" lazy
              :src="apiUrl + 'static/code/' + scope.row.detailCode.split('-')[0]"
              :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode.split('-')[0],]">
              <template #error><img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /></template>
            </el-image>
          </template>
          <template v-slot:checkStatus="scope">
            <!-- {{JSON.stringify(scope)}} -->
            <el-link v-if="scope.row.checkStatus == '已盘点' && scope.row.total == scope.row.origin" type="success"
              size="small" disabled>正常</el-link>
            <el-link v-else-if="scope.row.checkStatus == '已盘点' && scope.row.total != scope.row.origin" type="warning"
              size="small" disabled>异常</el-link>
            <el-link v-else type="danger" size="small" disabled>{{ scope.row.checkStatus }}</el-link>
          </template>
          <template v-slot:toolbar="scope">
            <el-link @click="openMarkEvent(scope.row)" type="primary" size="small">查看</el-link>
          </template>
        </static-table>
      </el-tab-pane>
      <el-tab-pane name="remain">
        <template #label>
          <span>
            <i class="el-icon-warning-outline"></i>
            未盘点 <span v-if="noCount > 0">{{ noCount || 0 }}</span>
          </span>
        </template>

        <el-form :inline="true" @submit.prevent>
          <el-form-item label="关键词">
            <el-input v-model="querys.keyword" placeholder="搜索未盘点的货品" @keyup.enter="fetchs"> </el-input>
          </el-form-item>
          <el-form-item label="" label-width="0" v-if="selects.length > 0">
            <el-button type="primary" icon="Select" @click="batchEvent">批量盘点</el-button>
          </el-form-item>
        </el-form>

        <static-table class="table" :pager="querys" :columns="querys.columns" :currHandler="currHandler"
          :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler"
          :seleHandler="selectionHandler">

          <template v-slot:detailImg="scope">
            <el-image style="height: 32px; width: 32px" lazy
              :src="apiUrl + 'static/code/' + scope.row.detailCode.split('-')[0]"
              :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode.split('-')[0],]">
              <template #error><img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /></template>
            </el-image>
          </template>

          <template v-slot:checkStatus="scope">
            <!-- {{JSON.stringify(scope)}} -->
            <el-link v-if="scope.row.checkStatus == '已盘点' && scope.row.total == scope.row.origin" type="success"
              size="small" disabled>正常</el-link>
            <el-link v-else-if="scope.row.checkStatus == '已盘点' && scope.row.total != scope.row.origin" type="warning"
              size="small" disabled>异常</el-link>
            <el-link v-else type="danger" size="small" disabled>{{ scope.row.checkStatus }}</el-link>
          </template>
          <template v-slot:toolbar="scope">
            <el-link @click="openMarkEvent(scope.row)" type="primary" size="small">查看</el-link>
          </template>
        </static-table>

      </el-tab-pane>
      <!-- <el-tab-pane name="notfound">
        <template #label>
          <span>
            <i class="el-icon-circle-close" style="color: #f56c6c"></i> 未找到
            {{ querys.notfound.length || 0 }}
          </span>
        </template>
        <div v-for="v in querys.notfound" :key="v" class="text item">
          {{ "货号：" + v + "；" }}
        </div>
      </el-tab-pane> -->
    </el-tabs>
  </el-card>

  <static-dialog ref="detailDialog" class="dialog" title="整体盘点情况" :ok="saveEndEvent" :width="'65%'"
    :okVisible="!finishStatus">
    <el-form :model="master" ref="master">
      <el-row :gutter="10" v-if="!finishStatus">
        <el-col :span="24">
          <el-form-item label="盘点操作：" :label-width="110">
            <el-radio-group v-model="master.status">
              <el-radio :label="parseInt(10)" :value="parseInt(10)" border v-if="this.status == '0'">结束盘点</el-radio>
              <el-radio :label="parseInt(-1)" :value="parseInt(-1)" border v-if="this.status == '0'">取消盘点</el-radio>
              <el-radio :label="parseInt(0)" :value="parseInt(0)" border v-if="this.status == '1'">开启盘点</el-radio>
              <el-radio :label="parseInt(1)" :value="parseInt(1)" border v-if="this.status == '0'">暂停盘点</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="盘点情况：" :label-width="110">
            <div style="margin-right: 10px;">
              <span style="color:red;" v-if="master.diffNumber > 0">盘点异常</span>
              <span style="color:blue;" v-if="master.diffNumber == 0">盘点正常</span>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="库存总数：" :label-width="110">
            {{ master.originNumber }}
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="本次盘点数量：" :label-width="110">
            {{ master.totalNumber }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="盘点人员：" :label-width="110">
            {{ master.checkUerNames }}，复核人：{{ master.reviewerName }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="差异数量：" :label-width="110">
            {{ master.diffNumber }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="差异金额：" :label-width="110">
            {{ master.diffMoney }}
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="差异报告：" prop="diffReason" :label-width="110">
            <el-input type="textarea" v-model="master.diffReason" style="max-width:90%" maxlength="2500" show-word-limit
              rows="3" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="差异处理：" prop="diffResult" :label-width="110">
            <el-input type="textarea" v-model="master.diffResult" style="max-width:90%" maxlength="2500" show-word-limit
              rows="3" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="盘点备注：" prop="remark" :label-width="110">
            <el-input type="textarea" v-model="master.remark" style="max-width:90%" maxlength="1000" show-word-limit
              rows="3" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </static-dialog>

  <static-dialog ref="itemDialog" class="dialog" title="货品盘点详情" :ok="saveMarkEvent" :width="'65%'">
    <el-form :model="forms" ref="forms" :label-width="110">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="盘点库房：" prop="storeCode">
            {{ forms.storeCode }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="品牌名称：" prop="brandName">
            {{ forms.brandName }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="品牌款号：" prop="brandModelCode">
            {{ forms.brandModelCode }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="品牌货号：" prop="brandSerialCode">
            {{ forms.brandSerialCode }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="货品名称：" prop="detailName">
            {{ forms.detailName }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="系统款号：" prop="detailCode">
            {{ forms.detailCode }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="系统货号：" prop="serialCode">
            {{ forms.serialCode }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="盘点情况：">
            库存数：{{ forms.origin }}，盘点数： {{ forms.total }}
            <div style="margin-left: 55px;">
              <span style="color:red;" v-if="forms.origin != forms.total">盘点异常</span>
              <span style="color:blue;" v-if="forms.origin == forms.total">盘点正常</span>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="盘点人员：" prop="updateUserName">
            {{ forms.updateUserName }}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {{ forms.updateDate }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="18">
          <el-form-item label="盘点备注：" prop="remark">
            <el-input v-model="forms.remark" style="max-width:90%" />
          </el-form-item>
        </el-col>
      </el-row>


    </el-form>
  </static-dialog>
</template>
<script>
import { createCheckBill, checkSingle, checkBatch, fetchCheckDetail, fetchCheckReport, saveCheckEnd, saveCheckDetail } from "@/api/stock";
import StaticTable from "@/components/static-table.vue";
import StaticDialog from "@/components/static-dialog.vue";

import wxJsSdk from '@/utils/wxsdk.js'

export default {
  name: "StockCheck",
  components: { StaticTable, StaticDialog },
  data() {
    return {
      keyword: "",
      total: 1,
      remark: '',
      apiUrl: "",
      tabName: "current",
      billCode: '',
      status: 0,
      master: {},
      report: {},
      // querys: {
      //   keyword: "",
      //   //remain: [], //未盘点
      //   current: [], //已盘点
      //   notfound: [],
      //   currentKeyword: "",
      //   //remainKeyword: "",
      // },
      billTypes: [{ value: "库存盘点", label: "库存盘点" }],

      // props: { checkStrictly: true, },
      companys: [],
      companyId: null,

      querys: {
        keyword: "",
        columns: [
          { type: "selection", width: "40" },
          { type: "index", label: "序号" },
          { type: "template", prop: "checkStatus", label: "状态", width: "60", },
          { prop: "storeCode", label: "库房", sortable: true, searchable: true, width: "65", },
          { prop: "channel", label: "渠道", sortable: true, searchable: true, width: "70", showOverflowTooltip: true, },
          { prop: "detailCode", label: "款号", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { type: "template", prop: "detailImg", label: "图片", width: "55", },
          { prop: "detailName", label: "名称", sortable: true, searchable: true, width: "160", showOverflowTooltip: true },
          { prop: "serialCode", label: "货号", sortable: true, searchable: true, width: "100", showOverflowTooltip: true },
          { prop: "brandName", label: "品牌", sortable: true, searchable: true, width: "70", showOverflowTooltip: true },
          { prop: "brandSerialCode", label: "品牌货号", sortable: true, searchable: true, width: "100", showOverflowTooltip: true },
          { prop: "brandModelCode", label: "品牌款号", sortable: true, searchable: true, width: "100", showOverflowTooltip: true },
          { prop: "certNumber", label: "证书号", sortable: true, searchable: true, width: "110", showOverflowTooltip: true },
          { prop: "weight", label: "重量", width: "50", },
          { prop: "origin", label: "系统数", width: "60", },
          { prop: "total", label: "盘点数", width: "60", },
          { prop: "unitName", label: "规格", width: "50", showOverflowTooltip: true },
          { prop: "purchasePrice", label: "采购价", width: "65", showOverflowTooltip: true },
          { prop: "productPrice", label: "入库价", width: "70", showOverflowTooltip: true },
          { prop: "updateUserName", label: "盘点人", width: "60", },
          { prop: "remark", label: "备注", sortable: true, searchable: true, width: "130", showOverflowTooltip: true },
          { type: "toolbar", label: "操作", width: "80" },
        ],
        sidx: "id", //默认的排序字段
        sord: "descending",
      },

      msgNotity: null,
      hasCount: 0,
      noCount: 0,

      loopscan: 0,
      selects: [],
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    searchEvent() {
      if (this.keyword && this.keyword.length > 0) {
        //let companyID = this.companyId[this.companyId.length - 1];
        checkSingle(this.master.billCode, this.companyId, this.keyword, this.total, this.remark).then((res) => {
          if (res.code == 200 && res.data) {
            if (this.msgNotity) this.msgNotity.close();
            this.msgNotity = this.$notify({
              title: "盘点成功",
              message: "[" + this.keyword + "]" + res.content,
              type: "success",
              duration: 30000
            });

            this.tabName = "current";

            this.querys.keyword = this.keyword;

            this.keyword = '';
            this.total = 1;
            this.remark = '';

            //避免请求api
            this.querys.page = 1;
            this.querys.data = res.data;

            this.hasCount += res.data.length;
            this.noCount -= res.data.length;
          } else {
            this.$alert("[" + this.keyword + "]" + res.content, '提示')
          }

          setTimeout(function () {
            this.scanBarCode();
          }, 300);
        },
          (err) => {
            this.$alert(err.content, '提示')
            this.loopscan = 0;
          });
      }
    },
    batchEvent() {
      let that = this;
      if (this.selects.length == 0) {
        this.$message.error("操作失败，请勾选要批量盘点的货品");
        return;
      }

      let idArray = [];
      for (let i = 0; i < this.selects.length; i++) {
        idArray.push(this.selects[i].id);
      }

      //let companyID = this.companyId[this.companyId.length - 1];
      checkBatch(this.master.billCode, this.companyId, idArray, this.total, this.remark).then((res) => {
        if (res.code == 200) {
          if (this.msgNotity) this.msgNotity.close();
          this.msgNotity = this.$notify({
            title: "盘点成功",
            message: res.content,
            type: "success",
            duration: 30000
          });

          this.tabName = "current";
          this.tabChange();
        } else {
          this.$alert(res.content, '提示')
        }
      },
        (err) => {
          this.$alert(err.content, '提示')
        });
    },

    tabChange() {
      this.querys.keyword = '';
      this.fetchs()
    },
    fetchs() {
      this.querys.filters = [];

      //let companyIdStr = this.companyId[this.companyId.length - 1];
      this.master.billCode = this.master.billCode.split('-')[0] + '-' + this.companyId.substr(20, 4);
      this.querys.filters.push({
        name: "billCode",
        value: this.master.billCode,
        operate: 0,
      });
      if (this.companyId && this.companyId.length > 0) {
        this.querys.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 0,
        });
      }

      if (this.tabName == 'current') {
        this.querys.filters.push({
          name: "checkStatus",
          value: '已盘点, 已核对, 盘盈',
          operate: 6,
        });
      } else if (this.tabName == 'remain') {
        this.querys.filters.push({
          name: "checkStatus",
          value: '未盘点, 待盘点, 盘亏',
          operate: 6,
        });
      }

      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.querys,
      };

      fetchCheckDetail(search).then((res) => {
        this.querys = { ...res.data, columns: [...this.querys.columns] };

        if (this.tabName == 'current')
          this.hasCount = this.querys.records;
        else if (this.tabName == 'remain')
          this.noCount = this.querys.records;
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.querys.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.querys.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.querys.sidx = order.prop;
      this.querys.sord = order.order;
      this.fetchs();
    },
    selectionHandler(rows) {
      this.selects = rows;
    },
    scanEvent() {
      if (!wxJsSdk.isWeixin()) {
        this.$alert('请使用手机微信进入该功能页面', '提示');
        return;
      }

      this.loopscan = 1;

      this.scanBarCode();
    },
    scanBarCode() {
      if (this.loopscan == 0 || this.loopscan > 50) //如果不是点击按钮进入的
        return;

      if (!wxJsSdk.isWeixin()) {
        this.$alert('请使用手机微信进入该功能页面', '提示');
        this.loopscan = 0;
        return;
      }

      wxJsSdk.scanQRCode().then(
        res => {
          console.log(JSON.stringify(res));
          if (this.$util.isBase64(res.resultStr))
            this.keyword = this.$util.base64Decode(res.resultStr);
          else if (res.resultStr.indexOf(',') > 0)
            this.keyword = res.resultStr.split(',')[1];
          else if (res.resultStr.indexOf('https://gw.chowtaiseng.com/q/?id=') >= 0)
            this.keyword = res.resultStr.replace('https://gw.chowtaiseng.com/q/?id=', '')
          else
            this.keyword = res.resultStr;

          this.loopscan++;
          this.searchEvent()
        },
        err => {
          alert(JSON.stringify(err));
          this.loopscan = 0;
        })
    },
    openEndEvent() {
      fetchCheckReport(this.master.billCode).then((res) => {
        this.report = res.data.report;
        this.master = res.data.master;
        this.status = this.master.status;
        if (this.master.status == undefined || this.master.status == 0)
          this.master.status = 10;
        this.$refs.detailDialog.toggle();
      });
    },
    saveEndEvent() {
      let that = this;
      if ((this.master.diffReason || '').length > 2500) {
        this.$message.error("差异报告字数超过最大长度限制");
        return;
      }
      if ((this.master.diffResult || '').length > 2500) {
        this.$message.error("差异处理字数超过最大长度限制");
        return;
      }
      if (this.master.status == undefined || this.master.status == null) {
        this.$message.error("请选择本次盘点状态操作");
        return;
      }

      this.$confirm('确认保存盘点的信息', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          //this.master.status = 10;//结束
          saveCheckEnd(this.master).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              this.fetchs();
              this.openEndEvent();
            } else {
              that.$alert(res.content, '提示');
            }
          }, (res) => {
            that.$alert(res.content, '提示');
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "修改失败", });
        });
    },


    openMarkEvent(row) {
      this.forms = row;
      this.$refs.itemDialog.toggle();
    },
    saveMarkEvent() {
      let that = this;
      this.$confirm('确认修改该盘点货品的信息', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          saveCheckDetail(this.forms).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.$refs.itemDialog.toggle();
            } else {
              that.$alert(res.content, '提示');
            }
          }, (res) => {
            that.$alert(res.content, '提示');
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "修改失败", });
        });
    },

    companyChange() {
      //let companyID = this.companyId[this.companyId.length - 1];

      createCheckBill(this.companyId, this.billCode).then((res) => {
        this.master = { ...res.data };
        //this.companyId = [];
        //this.companyId.push(this.master.companyID);
        this.companyId=this.master.companyID;
        this.status = this.master.status;
        this.fetchs();
      });
    }
  },
  //在Vue的生命周期Created函数中注册相关事件
  created() {
    // wxJsSdk.initJssdk();
    //console.log(wxJsSdk.signLink());
  },
  mounted() {
    this.apiUrl = process.env.VUE_APP_BASEURL;
    this.billCode = this.$route.query.billCode;

    this.$kaung.companys().then((res) => {
      this.companys = res;
      if ((this.billCode || '').length == 0 && res.length > 0)
        this.companyId = res[0].value;
      //this.companyId.push(res[0].value);

      this.companyChange();
    });
  },
  computed: {
    finishStatus() {
      //草稿或者退回状态
      if (this.status == -1 || this.status == 10) {
        return true;
      }
      return false;
    },
  },
};
</script>