import request from '@/utils/request.js'
import wx from 'weixin-js-sdk' // 引入微信js-sdk

// export const wechatShare = (tag, share_title, share_desc, share_link, share_cover) => {
//     var localUrl = window.location.href.split('#')[0];
//     const cover = share_cover || location.origin + '/static/favicon.ico'; //默认图片地址
//     return new Promise((resolve, reject) => {
//         http.request({
//             url: process.env.VUE_APP_BASEURL + '/Wechat/getJSSDKSignature',
//             method: 'GET',
//             data: {
//                 url: localUrl
//             },
//         }).then((res) => {
//             if (res.code == 200) {
//                 console.log('getJSSDKSignature res', res.data);
//                 let data = res.data
//                 const config = {
//                     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//                     appId: APPID, // 必填，公众号的唯一标识
//                     timestamp: data.timestamp, // 必填，生成签名的时间戳
//                     nonceStr: data.noncestr, // 必填，生成签名的随机串
//                     signature: data.signature, // 必填，签名
//                     jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表，注意查看官方文档，部分js接口即将废弃，我这里用的是新的
//                     openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表（当前标签用于跳转微信小程序）
//                 };
//                 tag.$wx.config(config); //通过config接口注入权限验证配置
//                 tag.$wx.ready(function () { //通过ready接口处理成功验证
//                     console.log("js-sdk配置成功！");
//                     //分享给朋友
//                     tag.$wx.updateAppMessageShareData({
//                         title: share_title || "默认标题", // 分享标题
//                         desc: share_desc || "默认描述", // 分享描述
//                         link: `${wx_host}#/${share_link}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//                         imgUrl: cover, // 分享后显示的封面图
//                         success: function () {
//                             console.info("分享给朋友");
//                         }, // 设置成功回调
//                     });

//                     //分享到朋友圈
//                     tag.$wx.updateTimelineShareData({
//                         title: share_title || "默认标题", // 分享标题
//                         link: `${wx_host}#/${share_link}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//                         imgUrl: cover, // 分享图标
//                         success: function () {
//                             // 用户点击了分享后执行的回调函数
//                             console.info("分享到朋友圈");
//                         }
//                     })
//                     return resolve(true)
//                 });
//                 tag.$wx.error(function (res) {
//                     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
//                     console.info("config信息验证失败");
//                     console.info(res);
//                     return reject(false)
//                 });
//             }
//         });
//     })
// }


// // 使用方法
// import { wechatShare } from "@/utils/wechatSdk.js";
// wechatShare(
//     this,
//     "分享标题",
//     "分享描述",
//     "分享链接",
//     "分享封面（配图）"
// ).then((res) => {
//     //自定义回调内容
// });


export function jsapiSignature(url) {
    return request({
        url: 'Wechat/JsapiSignature?url=' + encodeURIComponent(url || ''),
        method: 'get'
    });
}


var wxJsSdk= {
    signLink() {
        if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
            window.entryUrl = document.location.href
        }
        return /(Android)/i.test(navigator.userAgent) ? document.location.href : window.entryUrl;
    },

    // 当前是否是微信环境
    isWeixin() {
        return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    },

    /**
     * 初始化wechat(分享配置)
     */
    wechat() {
        return new Promise((resolve, reject) => {
            let url = this.signLink()
            jsapiSignature(url)
                .then(res => {
                    if (res.code == 200) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.data.appId, // 必填，公众号的唯一标识
                            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                            signature: res.data.signature, // 必填，签名
                            jsApiList: [
                                'updateAppMessageShareData', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                                'updateTimelineShareData', // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
                                'scanQRCode', // 扫一扫
                                'getLocation', // 获取地理位置
                                'openLocation', // 使用微信内置地图查看位置接口
                                'chooseImage', //拍照或从手机相册中选图接口
                                'closeWindow', //关闭当前网页窗口接口
                            ]
                        })
                        wx.ready(res => {
                            // 微信SDK准备就绪后执行的回调。
                            resolve(wx, res)
                        })
                        wx.error(err => {
                            reject(wx, err)
                        })
                    }
                })
        })
    },

    // 微信分享
    wxShare(shareObj) {
        this.wechat().then((wx, res) => {
            wx.ready(() => {
                wx.updateAppMessageShareData({
                    title: shareObj.title, // 分享标题
                    link: shareObj.link, // 分享链接
                    desc: shareObj.desc, // 分享描述
                    imgUrl: shareObj.imgUrl,
                    success: function () { },
                    cancel: function () { }
                });
                wx.updateTimelineShareData({
                    title: shareObj.title, // 分享标题
                    link: shareObj.link, // 分享链接
                    desc: shareObj.desc, // 分享描述
                    imgUrl: shareObj.imgUrl,
                    success: function () { },
                    cancel: function () { }
                });
            })
        })
    },


    // 扫一扫
    scanQRCode() {
        return new Promise((resolve, reject) => {
            this.wechat().then((wx, res) => {
                this.toPromise(wx.scanQRCode, {
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                }).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            })
        })
    },

    // 获取地理位置接口
    getLocation() {
        return new Promise((resolve, reject) => {
            this.wechat().then((wx, res) => {
                this.toPromise(wx.getLocation, {
                    type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                }).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            })
        })
    },

    // 使用微信内置地图查看位置接口
    openLocation(data) {
        return new Promise((resolve, reject) => {
            this.wechat().then((wx, res) => {
                this.toPromise(wx.openLocation, {
                    latitude: data.latitude, // 纬度，浮点数，范围为90 ~ -90
                    longitude: data.longitude, // 经度，浮点数，范围为180 ~ -180。
                    name: '', // 位置名
                    address: '', // 地址详情说明
                    scale: 1, // 地图缩放级别,整型值,范围从1~28。默认为最大
                    infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
                }).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            })
        })
    },

    // 拍照或从手机相册中选图接口
    chooseImage() {
        return new Promise((resolve, reject) => {
            this.wechat().then((wx, res) => {
                this.toPromise(wx.chooseImage, {
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                }).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            })
        })
    },

    // 关闭当前网页窗口接口
    closeWindow() {
        this.wechat().then((wx, res) => {
            wx.ready(() => {
                wx.closeWindow();
            })
        })
    },



    toPromise(fn, config = {}) {
        return new Promise((resolve, reject) => {
            fn({
                ...config,
                success(res) {
                    resolve(res);
                },
                fail(err) {
                    reject(err);
                },
                complete(err) {
                    reject(err);
                },
                cancel(err) {
                    reject(err);
                }
            });
        });
    },
}

export default wxJsSdk